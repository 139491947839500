import axios from '@axios'
import { openDB } from 'idb'
import Localbase from 'localbase'
import store from '@/store'

function getStoreProducts(params) {
  return new Promise((resolve, reject) => {
    axios
      .get('/v1/store_products', {
        params,
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getProducts = storeId => {
  openDB(`store-${storeId}`, 1, {
    async upgrade(db) {
      db.createObjectStore('products', {
        keyPath: 'id',
      })
      db.createObjectStore('orders', {
        autoIncrement: true,
      })
      let res = null
      let page = 1
      /* eslint-disable */
      do {
        res = await getStoreProducts({
          by_store: storeId,
          meta: {
            pagination: {
              page: page,
              per_page: 250,
            },
          },
        })
        res.data.data.forEach(product => {
          const sku = String(Number(product.product_attributes.sku))
          db.add('products', {
            ...product,
            name: product.product_attributes.name,
            sku,
          })
        })
        if (page === res.data.meta.pagination.total_pages) {
          store.dispatch('pos/handleLunr', storeId)
        }
        page++
      } while (page <= res.data.meta.pagination.total_pages)
    },
  })
  store.dispatch('pos/handleLunr', storeId)
}

export default function dbPromise(storeId) {
  return new Promise(() => {
    if (!localStorage.getItem(`lastUpdate-${storeId}`)) localStorage.setItem(`lastUpdate-${storeId}`, new Date())

    const lastUpdateDay = new Date(localStorage.getItem(`lastUpdate-${storeId}`))
    const date1 = `${lastUpdateDay.getFullYear()}/${lastUpdateDay.getMonth()}/${lastUpdateDay.getDate()}`

    const todayDate = new Date()
    const date2 = `${todayDate.getFullYear()}/${todayDate.getMonth()}/${todayDate.getDate()}`

    if (new Date(date1) < new Date(date2)) {
      localStorage.setItem(`lastUpdate-${storeId}`, new Date())
      const indexedDB = new Localbase(`store-${storeId}`)
      indexedDB.delete()
        .then(() => {
          getProducts(storeId)
        })
    } else {
      getProducts(storeId)
    }

  })
}
