<template>
  <div
    class="keyboard-panel"
    :class="isKeyboardVisible ? 'showKeyboard' : 'hideKeyboard'"
  >
    <div class="position-relative">
      <div
        class="hideNShowKeyboard d-flex justify-content-center align-items-center"
        @click="isKeyboardVisible = !isKeyboardVisible"
      >
        <i class="fas fa-keyboard" />
      </div>
      <div :class="keyboardClass" />
    </div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'SimpleKeyboard',
  components: {

  },
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    keyboard: null,
    isKeyboardVisible: false,
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input)
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    })
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      })
    },
  },
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.keyboard-panel {
  width: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 100000;
  transition: bottom .5s ease;
}
.hideKeyboard{
  bottom: -230px;
}
.showKeyboard {
  bottom: 0px;
}
.hideNShowKeyboard {
  position: absolute;
  top: -40px;
  width: 80px;
  height: 40px;
  right: 100px;
  border-radius: 10px 10px 0 0;
  background: #ECECEC;
}
</style>
