<template>
  <div>
    <!-- Products search -->
    <div class="mb-2">
      <b-input-group>
        <b-form-input
          id="sku"
          v-model="searchQuery"
          variant="outline-primary"
          autocomplete="off"
          placeholder="Nombre o Código de barras"
          @input="lookupStoreProducts"
        />
        <b-input-group-append v-if="!searchQuery">
          <b-button
            variant="outline-primary"
            @click="showModal()"
          >
            <i class="fas fa-calculator text-white" />
          </b-button>
          <numpad
            ref="numpadModal"
            @ok="numpadSearch($event)"
          />
          <b-button
            v-b-toggle.posSettingsSidebar
            variant="outline-primary"
          >
            <i class="fas fa-wrench text-white" />
          </b-button>
        </b-input-group-append>
        <b-input-group-append v-else>
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <categories-pos v-if="settings.showCategories" />
    </div>
    <div
      v-if="searchQuery && !settings.showCategories"
      class="checkout-items"
    >
      <b-card
        v-for="product in storeProducts"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="">
          <b-link>
            <b-img
              :src="product.product_attributes.logo"
              :alt="`${product.product_attributes.name}-${product.id}`"
              width="120"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ product.product_attributes.name }}
              </b-link>
            </h6>
            <div class="item-rating">
              {{ product.product_attributes.variant }}
            </div>
          </div>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ product.unit_price * product.units | money }}
              </h4>
              <p
                v-if="product.hasFreeShipping"
                class="card-text shipping"
              >
                <b-badge
                  pill
                  variant="light-success"
                >
                  Free Shipping
                </b-badge>
              </p>
            </div>
          </div>
          <b-button
            variant="success"
            class="mt-1 remove-wishlist"
            @click="addProductAndClearQuery(product)"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span>Agregar</span>
          </b-button>
        </div>
      </b-card>
    </div>
    <b-pagination
      v-if="settings.showCategories"
      v-model="pagination.page"
      :total-rows="pagination.total_objects"
      :per-page="pagination.per_page"
      first-number
      last-number
      class="mb-0 mb-sm-4 mb-lg-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
      @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="18"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="18"
        />
      </template>
    </b-pagination>

    <SimpleKeyboard
      :input="searchQuery"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import CategoriesPos from '@/views/e-commerce/e-commerce-checkout/CategoriesPOS.vue'
import Numpad from '@core/components/NumPad.vue'

import SimpleKeyboard from '@/components/keyboard.vue'
import { ref } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import { formatDate } from '@core/utils/filter'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'

export default {
  components: {
    Numpad,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    CategoriesPos,
    SimpleKeyboard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const products = ref([])

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
        })
    }

    // const fetchCartProducts = () => {
    //   store.dispatch('app-ecommerce/fetchCartProducts')
    //     .then(response => {
    //       this.products.value = response.data.products
    //     })
    // }
    // fetchCartProducts()

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    }
  },
  props: {
    barcodeScanned: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      searchQuery: '',
      isCameraScannerActive: true,
    }
  },
  computed: {
    ...mapGetters('storeProducts', ['storeProducts', 'pagination']),
    ...mapGetters('categories', ['currentCategory']),
    ...mapGetters('pos', ['settings']),
  },
  watch: {
    barcodeScanned(val) {
      this.searchQuery = val
      this.lookupStoreProducts(val)
    },
  },
  mounted() {
    /* document.addEventListener('keydown', () => {
      document.getElementById('sku').focus()
    }) */
  },
  methods: {
    ...mapActions('storeProducts', ['getStoreProducts']),
    ...mapActions('pos', ['addProductToCart']),
    showModal() {
      this.$refs.numpadModal.show()
    },
    numpadSearch(val) {
      this.searchQuery = String(val)
      this.lookupStoreProducts(String(val))
    },
    lookupStoreProducts: debounce(function searchQuery(query) {
      if (query != null && query !== '') {
        this.getStoreProducts({
          by_store: this.$route.params.store_id,
          by_name_or_sku: query,
          offline: JSON.parse(localStorage.getItem('offlineMode')),
        }).then(response => {
          if (response.data.length === 1) {
            if (navigator.vibrate) {
              navigator.vibrate(200)
            }
            this.addProductToCart(response)
            this.searchQuery = null
          }
        })
      } else if (query === null || query === '') {
        this.getStoreProducts({
          by_store: this.$route.params.store_id,
        })
      }
    }, 250),
    addProductAndClearQuery(product) {
      // eslint-disable-next-line
      const audio = new Audio(require('@/assets/sounds/Beep2.wav'))
      audio.play()
      if (navigator.vibrate) {
        navigator.vibrate(200)
      }
      this.addProductToCart({ data: [{ ...product }] })
      this.searchQuery = null
    },
    clearSearchbarAndResetSearch() {
      this.searchQuery = null
      this.lookupStoreProducts(null)
    },
    handlePagination({ page, per_page }) {
      this.getStoreProducts({
        by_store: this.$route.params.store_id,
        by_category: this.currentCategory,
        by_name: this.searchQuery || null,
        by_active_status: true,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
    },
    onChange(input) {
      this.searchQuery = input
      this.lookupStoreProducts(input)
    },
  },
}
</script>

<style lang="scss" scoped>
.z-index-top {
  z-index: 10;
}
</style>
