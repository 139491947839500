<template>
  <div>
    <b-input-group>
      <b-form-input
        id="sku"
        v-model="searchText"
        type="text"
        autocomplete="off"
        class="mb-1"
      />
      <b-input-group-append v-if="!searchText">
        <b-button
          v-b-toggle.posSettingsSidebar
          variant="outline-primary"
        >
          <i class="fas fa-wrench text-white" />
        </b-button>
      </b-input-group-append>
      <b-input-group-append v-else>
        <b-button
          variant="outline-warning"
          @click="clearSearchbarAndResetSearch()"
        >
          Borrar
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <div
      v-if="searchText != null && searchText != ''"
    >
      <product-card
        v-for="(item, n) in results.filter(x => x.score > 0)"
        :key="n"
        :product="productsDictionary[item.ref]"
        @productAddedToCart="searchText = ''"
      />
    </div>

  </div>
</template>
<script>
/* eslint-disable */
import lunr from 'lunr'
import Localbase from 'localbase'
import productCard from '@/views/test/ProductCard.vue'

export default {
  components: {
    productCard
  },
  data() {
    return {
      results: [],
      searchText: '',
      idx: null,
      allItems: [],
      productsDictionary: {},
    }
  },
  created () {
    let that = this
    let db = new Localbase(`store-${this.$route.params.store_id}`)
    db.collection('products').get()
      .then(products => {
        that.productsDictionary = Object.assign({}, ...products.map(x => ({ [x.id]: x })))
        that.allItems = products
      })
      .then(() => {
        this.idx = lunr(function () {
          this.field('name')

          that.allItems.forEach(function (doc) {
            this.add({
              ...doc,
              name: doc.product_attributes.name,
              sku: doc.product_attributes.sku,
            })
          }, this)
        })
      })
  },
  watch: {
    searchText(newValue) {
      if ( newValue && newValue != '' ) this.results = this.idx.search(`${newValue}~1`)
    }
  },

}
</script>
