import { render, staticRenderFns } from "./Pos.vue?vue&type=template&id=57afec52&scoped=true&"
import script from "./Pos.vue?vue&type=script&lang=js&"
export * from "./Pos.vue?vue&type=script&lang=js&"
import style0 from "./Pos.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Pos.vue?vue&type=style&index=1&id=57afec52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57afec52",
  null
  
)

export default component.exports