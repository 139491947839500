<template>
  <b-sidebar
    id="posSettingsSidebar"
    bg-variant="white"
    right
    shadow
    backdrop
  >
    <div
      class="p-1 d-flex flex-column justify-content-between h-100"
    >
      <div>
        <div class="mb-1">
          <b-form-checkbox
            v-model="print"
            name="check-button"
            class="custom-control-success"
            switch
            inline
          >
            Imprimir Tickets
          </b-form-checkbox>
        </div>
        <div class="mb-1">
          <b-button
            variant="outline-success"
            size="sm"
            class="mt-3"
            block
            href="/help-videos"
          >
            Ayuda
          </b-button>
        </div>
        <div class="mb-1">
          <b-button
            variant="outline-primary"
            size="sm"
            class="mt-3"
            block
            @click="startSerial()"
          >
            Conectar bascula
          </b-button>
        </div>
        <div class="mb-1">
          <b-button
            v-b-modal.loadingOffline
            variant="outline-warning"
            size="sm"
            class="mt-3"
            block
            @click="restoreOfflineStoreProducts()"
          >
            Actualizar productos
          </b-button>
        </div><div class="mb-1">
          <b-button
            variant="outline-danger"
            size="sm"
            class="mt-3"
            block
            @click="$router.go(-1)"
          >
            Regresar
          </b-button>
        </div>
      </div>

      <div class="mb-1">
        <b-button
          variant="outline-warning"
          size="sm"
          class="mt-1"
          block
          @click="handleReboot()"
        >
          <i class="fas fa-sync mr-1" />   Reiniciar equipo
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          class="mt-1"
          block
          @click="handleShutdown()"
        >
          <i class="fas fa-power-off mr-1" />  Apagar equipo
        </b-button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import dbPromise from '@/offline/products'

export default {
  data() {
    return {
      isCameraScannerActive: false,
      isCategoriesActive: true,
      print: true,
      isOfflineMode: false,
    }
  },
  computed: {
    ...mapGetters('pos', ['settings', 'offlineMode', 'starredProducts']),
  },
  watch: {
    isCategoriesActive(val) {
      this.toggleShowCategories(val)
    },
    print(val) {
      this.setPrint(val)
    },
    isCameraScannerActive(val) {
      this.toggleCameraScanner(val)
    },
    isOfflineMode(val) {
      localStorage.setItem('offlineMode', JSON.stringify(val))
      this.setOfflineMode(val)
    },
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('print')) === null) {
      localStorage.setItem('print', true)
      this.print = true
    } else if (JSON.parse(localStorage.getItem('print')) === true) {
      this.print = true
    } else {
      this.print = false
    }
    if (JSON.parse(localStorage.getItem('offlineMode')) === null) {
      localStorage.setItem('offlineMode', 'false')
    } else if (JSON.parse(localStorage.getItem('offlineMode')) === true) {
      this.isOfflineMode = true
    } else {
      this.isOfflineMode = false
    }
  },
  methods: {
    dbPromise,
    ...mapMutations('pos', [
      'toggleShowCategories',
      'toggleShowCamera',
      'setOfflineMode',
    ]),
    ...mapActions('weight', ['startSerial']),
    toggleCameraScanner(val) {
      this.toggleShowCamera(val)
    },
    toggleCategories(val) {
      this.toggleShowCategories(val)
    },
    setPrint(val) {
      localStorage.setItem('print', JSON.stringify(val))
    },
    ...mapActions('pos', ['reboot', 'shutdown']),
    ...mapActions('orders', ['dispatchBulkOrders', 'deleteOrdersFromIndexedDb']),
    ...mapActions('storeProducts', ['fetchOfflineStoreProducts']),
    ...mapMutations('pos', ['switchStarredProducts']),
    handleShutdown() {
      this.$swal({
        title: '¿Quieres apagar el equipo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.shutdown()
        }
      })
    },

    handleReboot() {
      this.$swal({
        title: '¿Quieres reiniciar el equipo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.reboot()
        }
      })
    },

    restoreOfflineStoreProducts() {
      this.dispatchBulkOrders(this.$route.params.store_id)
        .then(() => {
          this.deleteOrdersFromIndexedDb(this.$route.params.store_id)
          this.fetchOfflineStoreProducts(this.$route.params.store_id)
        })
        .catch(() => {
          this.fetchOfflineStoreProducts(this.$route.params.store_id)
        })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
