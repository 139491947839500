<template>
  <b-modal
    :id="`bascula-${product.id}`"
    @ok="product.units = Number(scaleValue).toFixed(3)"
    @cancel="scaleValue = String(product.units)"
  >
    <div class="display-2 text-right scaleDisplay">
      {{ scaleValue }}
    </div>
    <div class="numpad">
      <div
        v-for="n in 9"
        :key="`btn-${n}`"
        v-ripple
        class="btn"
        @click="typeNum(`${n}`)"
      >
        {{ n }}
      </div>
      <div
        v-ripple
        class="btn"
        @click="typeDot('.')"
      >
        .
      </div>
      <div
        v-ripple
        class="btn"
        @click="typeNum('0')"
      >
        0
      </div>
      <div
        v-ripple
        class="btn"
        @click="delNum()"
      >
        <i class="fas fa-backspace" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      scaleValue: '',
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
