<template>
  <b-card
    class="product-card mb-50"
    no-body
  >

    <!-- Product Image -->
    <div>
      <b-img
        :src="product.product_attributes.logo"
        :alt="`${product.product_attributes.name}-${product.id}`"
        width="95"
      />
    </div>

    <!-- Product Details: Card Body -->
    <div class="p-1">
      <p class="mb-0">
        {{ product.product_attributes.name }}
      </p>
      <p>
        {{ product.product_attributes.variant }}
      </p>
      <b-badge 
        pill
        variant="primary"
        v-if="product.store_product_variant_attributes.length"
        v-for="(variant, n) in product.store_product_variant_attributes"
        :key="'variant' + n"
      >
        {{ variant.variant_option_attributes.option_name }}
      </b-badge>
    </div>

    <!-- Product Options/Actions -->
    <div class="item-options text-center p-1">
      <h4 class="item-price mb-0">
        ${{ product.unit_price | money }}
      </h4>
      <b-button
        variant="success"
        class="mt-1 addToCart"
        size="sm"
        @click="addProductAndClearQuery(product)"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Agregar</span>
      </b-button>
    </div>
    <weight-modal :product="product" />
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import WeightModal from '@/views/pos/WeightModal.vue'

export default {
  components: {
    WeightModal,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('weight', ['weight']),
    ...mapGetters('pos', ['restockMode', 'productInventoryMode']),
  },
  methods: {
    ...mapActions('pos', ['addProductToCart', 'getPromotionTotal']),
    ...mapActions('weight', ['getWeight']),
    ...mapActions('storeProducts', ['editStoreProduct']),
    ...mapMutations('pos', [
      'setProductQuantity',
      'setProductWeight',
      'setShowCartModal'
    ]),
    addProductAndClearQuery(product) {
      // eslint-disable-next-line
      this.setShowCartModal(true)
      const audio = new Audio(require('@/assets/sounds/Beep2.wav'))
      if ((this.restockMode === true && this.product.has_inventory === true) || this.restockMode === false) {
        audio.play()
        if (navigator.vibrate) {
          navigator.vibrate(200)
        }
        this.addProductToCart({ data: [{ ...product }] })
          .then(() => {
            if (product.product_attributes.is_weighted) {
              this.getWeight()
                .then(() => {
                  // eslint-disable-next-line
                  const audio = new Audio(require('@/assets/sounds/Pop.wav'))
                  audio.play()
                  setTimeout(() => {
                    const x = this.weight.replace(/[^\d.-]/g, '')
                    this.setProductWeight({
                      cartItem: product,
                      units: Number(x),
                    })
                    this.getPromotionTotal({ data: [{ ...product }]})
                  }, 250)
                })
                .catch(() => {
                  this.$bvModal.show(`bascula-${product.id}`)
                })
            } else if (this.productInventoryMode === true) {
              this.$bvModal.show(`product-modal-${product.id}`)
            }
          })
        this.$emit('productAddedToCart')
      } else {
        this.$bvModal.msgBoxConfirm('El producto seleccionado no maneja invetario, ¿desea modificarlo?', {
          title: 'Mensaje de confirmación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SÍ',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
          .then(value => {
            if (value === true) {
              const productEdited = {
                ...product,
                has_inventory: true,
              }
              this.editStoreProduct(productEdited)
                .then(() => {
                  audio.play()
                  if (navigator.vibrate) {
                    navigator.vibrate(200)
                  }

                  this.addProductToCart({ data: [{ ...product }] })
                    .then(() => {
                      if (product.product_attributes.is_weighted) {
                        this.getWeight()
                          .then(() => {
                            // eslint-disable-next-line
                  const audio = new Audio(require('@/assets/sounds/Pop.wav'))
                            audio.play()
                            setTimeout(() => {
                              const x = this.weight.replace(/[^\d.-]/g, '')
                              this.setProductWeight({
                                cartItem: product,
                                units: Number(x),
                              })
                            }, 250)
                          })
                          .catch(() => {
                            this.$bvModal.show(`bascula-${product.id}`)
                          })
                      } else if (this.productInventoryMode === true) {
                        this.$bvModal.show(`product-modal-${product.id}`)
                      }
                    })
                  this.$emit('productAddedToCart')
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.product-card {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr auto;
  &:last-child {
    margin-bottom: 1rem !important;
  }
  .addToCart {
    &:focus {
      background-color: $warning !important;
      border-color: $warning !important;
    }
  }
}

@media (max-width: 320px) {
  .product-card {
    display: flex; 

    div {
      padding: 5px !important;
      text-align: center;
    }
    
    img {
      width: 75px;
      height: 75px;
    }

    h6 {
      font-size: .8rem;
    }

    h4 {
      font-size: 1rem;
    }

    button {
      font-size: .8rem;
    }
  }
}

@media (320px <= width <= 475px) {
  .product-card {
    font-size: 0.8rem !important;

    h6 {
      font-size: .9rem !important;
    }

    h4 {
      font-size: 1rem !important;
    }

    button {
      font-size: .8rem !important;
    }
  }
}
</style>
